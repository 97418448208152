<template>
    <Layout :checkout="carrinho.length > 0 ? true : false" color="#eaeaea">
        <div v-if="carrinho.length == 0" class="pre-carrinho animate__animated animate__fadeIn animate__delay-1s">
            <div style="text-align:center">
                <h1>{{$t_('voce_nao_possui')}}</h1>
                <p>{{$t_('escolha_seu_ingresso')}}</p>
                <img src="@/assets/images/img_maracana_tour.png" style="margin: 0px auto;" width="400"/>
                <v-btn 
                    class="checkout-button-retornar-home mt-5"
                    @click="goHome()"
                >
                    {{$t_('selecionar_meus_ingressos')}}
                </v-btn>
            </div>
        </div>
        <div v-else>
            <div class="checkout-container01" v-if="carrinho && carrinho[0] && carrinho[0].exp">
                <div class="checkout-container06 animate__animated animate__pulse animate__infinite" style="display: flex; justify-content: center; align-items: center;">
                    <svg viewBox="0 0 1024 1024" class="checkout-icon" >
                        <path
                            d="M512 128c-247.424 0-448 200.576-448 448s200.576 448 448 448 448-200.576 448-448-200.576-448-448-448zM512 936c-198.824 0-360-161.178-360-360 0-198.824 161.176-360 360-360 198.822 0 360 161.176 360 360 0 198.822-161.178 360-360 360zM934.784 287.174c16.042-28.052 25.216-60.542 25.216-95.174 0-106.040-85.96-192-192-192-61.818 0-116.802 29.222-151.92 74.596 131.884 27.236 245.206 105.198 318.704 212.578v0zM407.92 74.596c-35.116-45.374-90.102-74.596-151.92-74.596-106.040 0-192 85.96-192 192 0 34.632 9.174 67.122 25.216 95.174 73.5-107.38 186.822-185.342 318.704-212.578z">
                        </path>
                        <path d="M512 576v-256h-64v320h256v-64z"></path>
                    </svg>
                    <span class="checkout-text">
                        {{$t_("expirar")}}{{ countDown(carrinho[0].exp) }}
                    </span>
                </div>
            </div>
            <div class="checkout-container02">
                <div class="checkout-container03">
                    <div class="checkout-container04">
                        <h3 class="checkout-text01">{{$t_('produto_carrinho')}}</h3>
                    </div>
                    <CardProduto v-for="(car, i) in carrinho" :key="i" :car="car" :protect="extras.extras_response_front"/>
                    <div v-if="protect_group">
                      <div v-html="protect_group.html" class="checkout-protect-group" id="checkout-protect-group"></div>
                    </div>
                </div>
                <div class="checkout-container22">
                    <Resumo :protect="extras.extras_response_front" :protect_preco="protect_group?.data?.value" />
                </div>
            </div>
        </div>
        
    </Layout>
</template>

<script>

export default {
    name: 'Checkout',
    components: {
        Layout: () => import('@/layout/Default.vue'),
        Resumo: () => import('@/components/resumo.vue'),
        CardProduto: () => import('@/components/card-produtos-checkout.vue'),
    },

    data() {
        return {
            portador: { nome: '', show: false, item: '' },
            portador_: { nome: '', show: false, item: '' },
            tempo_exp: '00:00',
            dtd_exp: '00:00',
            carrinho: [],
            protect_group: false,
            extras: { extras_response_front: false }
        }
    },

    watch: {
        async '$carrinho.carrinho'(e){
            this.carrinho = e
            this.carrinho = this.carrinho.filter(function (item, index, array) {
                    return !this[JSON.stringify(array[index].idmovimentacao + array[index].idapresentacao)] && (this[JSON.stringify(item.idmovimentacao + item.idapresentacao)] = true)
            }, Object.create(null))

            this.carrinho.forEach(carrinho => {
                this.$google.checkoutStepOne(carrinho.produtos, this.$carrinho.resumo.cupom);

                carrinho.produtos.forEach(produto => {
                  if(produto.extra_html){
                    this.protect_group = produto.extra_html.quote_protect_group;

                    this.extras.extras_response_front = {
                      quote_protect_group: {
                        key: produto.extra_html.quote_protect_group.key,
                        productId: produto.extra_html.quote_protect_group.data.productId,
                        sold: null
                      }
                    }; 

                    let self = this;
                    setTimeout(() => {
                      const scriptRegex = /<script\b[^<]*>([\s\S]*?)<\/script>/i;
                      const match = scriptRegex.exec(produto.extra_html.quote_protect_group.html);

                      const scriptElement = document.createElement('script');
                      scriptElement.textContent = match[1];

                      document.body.appendChild(scriptElement);

                      document.addEventListener("pg-change", (e) => {
                        self.$root.loading = true
                        self.extras.extras_response_front.quote_protect_group.sold = e.detail.sold
                      });

                    }, 1000);
                  }
                })
            })
        },
    },

    methods: {
        goHome() {
            if (this.$route.name != 'Home') {
                this.$axios.rota('Home')
            } else {
                this.$vuetify.goTo(0);
            }
        },

        countDown(time) {
            this.dtd_exp = time
            const self = this

            window.setInterval(() => {
                self.tempo_exp = self.countDown_(self.dtd_exp)
            }, 1000);

            return self.tempo_exp;
            
        },
        countDown_(fim){
            var date1              = new Date(); 
            var date2              = new Date(fim); 

            let m = 
                (date1.getMinutes() < new Date(date2).getMinutes()) ? 
                (new Date(date2).getMinutes() - date1.getMinutes()) : 
                (59 - date1.getMinutes()) + new Date(date2).getMinutes()

            let s = 59 - date1.getSeconds()

            m = m.toString().length  == 1 ? '0' + m.toFixed(0) : m.toFixed(0);
            s = s.toString().length == 1 ? '0' + s.toFixed(0): s.toFixed(0);
 
            if(date1 > date2){
                return `00:00`
            }

            return `${m}:${s}`

        },


    },

    mounted() {
        
        if(!this.$axios.getLocal('session') && this.$axios.getLocal('local_carrinho')) {
            this.carrinho = this.$axios.getLocal('local_carrinho').data
            this.carrinho = this.carrinho.filter(function (item, index, array) {
                return !this[JSON.stringify(array[index].idmovimentacao + array[index].idapresentacao)] && (this[JSON.stringify(item.idmovimentacao + item.idapresentacao)] = true)
            }, Object.create(null))
        }

        
    }

}
</script>

<style scoped>
big {
    font-size: 25px;
    color: #27787f;
    font-weight: 900;
}
.pre-carrinho {
    width: 100%;
    min-height: 550px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    text-align: center;
}
.checkout-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #eaeaea;
}
.checkout-container01 {
  flex: 0 0 auto;
  width: calc(991px - 40px);
  display: flex;
  margin-top: 30px;
  align-items: center;
  margin-left: 10px;
  padding-top: 10px;
  border-color: #d2232aff;
  border-width: 1px;
  margin-right: 10px;
  padding-left: 10px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: 10px;
  padding-right: 10px;
  flex-direction: row;
  padding-bottom: 10px;
  justify-content: center;
  background-color: #ffacae;
}
.checkout-icon {
  width: 24px;
  height: 24px;
  margin-left: 20px;
  margin-right: 20px;
}
.checkout-text {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 22px;
    color: #860000;
}
.checkout-container02 {
  flex: 0 0 auto;
  width: 991px;
  display: flex;
  padding: 15px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-end;
}
.checkout-container03 {
  flex: 0 0 auto;
  width: calc(100% - 380px);
  display: flex;
  margin-top: 15px;
  align-items: flex-start;
  margin-right: 30px;
  flex-direction: column;
}
.checkout-container04 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 10px;
  align-items: flex-start;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: flex-start;
}
.checkout-text01 {
  color: var(--dl-color-gray-500);
  font-size: px;
}
.checkout-container05 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
}
.checkout-container05-prod {
  width: 563px;
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.checkout-container06 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.checkout-container07 {
  width: 100px;
  height: auto;
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: flex-start;
}
.checkout-text02 {
  color: var(--dl-color-gray-white);
  font-size: 34px;
}
.checkout-text03 {
  font-size: 12px;
}
.checkout-text04 {
  color: var(--dl-color-gray-white);
}
.checkout-text05 {
  color: var(--dl-color-gray-white);
  height: 22px;
}
.checkout-container08 {
  width: calc(100% - 40px);
  display: flex;
  padding: 10px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.checkout-text06 {
  font-size: 24px;
  text-transform: uppercase;
}

.checkout-text06-prod {
    font-size: 16px;
    text-transform: uppercase;
}

.checkout-text07 {
  color: var(--dl-color-gray-700);
  font-size: 12px;
}
.checkout-container09 {
  flex: 0 0 auto;
  width: 30px;
  height: auto;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.checkout-container10 {
  height: 60px;
  display: flex;
  position: relative;
  align-self: flex-end;
  align-items: stretch;
  flex-direction: column;
}
.checkout-icon03 {
  fill: #D9D9D9;
  width: 24px;
  height: 24px;
  align-self: flex-end;
  cursor: pointer;
}
.checkout-container11 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: 15px;
  align-items: center;
  margin-left: 0px;
  border-color: var(--dl-color-gray-700);
  border-style: dashed;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: 15px;
  flex-direction: row;
  justify-content: flex-start;
  border-top-width: 1px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
}
.checkout-container12 {
  flex: 0 0 auto;
  width: 18%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.checkout-text09 {
  color: black;
}
.checkout-container13 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.checkout-text10 {
  color: var(--dl-color-gray-700);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  margin-right: 5px;
  text-decoration: line-through;
}
.checkout-text11 {
  color: var(--dl-color-gray-700);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  text-decoration: line-through;
}
.checkout-text12 {
  color: var(--dl-color-gray-700);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  text-decoration: line-through;
}
.checkout-container14 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.checkout-text13 {
  color: var(--dl-color-principal);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  margin-right: 5px;
}
.checkout-text14 {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.checkout-text15 {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
}
.checkout-container15 {
  flex: 0 0 auto;
  width: 22%;
  height: auto;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.checkout-container15-more {
  flex: 0 0 auto;
  width: 82%;
  height: auto;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.checkout-container-portador {
  flex: 0 0 auto;
  width: 60%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.checkout-container16 {
  width: 120px;
  height: 35px;
  display: flex;
  padding: 2px;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 1px;
  border-radius: 18px;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f1f1f1;
}
.checkout-container17 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.checkout-container18 {
  flex: 0 0 auto;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  border-width: 0px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
}
.checkout-icon05 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
  margin-top: 2px;
}
.checkout-container19 {
  flex: 0 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.checkout-container20 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.checkout-container21 {
  flex: 0 0 auto;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-principal);
  border-width: 0px;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  justify-content: center;
}
.checkout-icon07 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
  margin-top: 2px;
}
.checkout-text18 {
  color: var(--dl-color-principal);
  font-size: 12px;
  align-self: center;
  text-align: center;
  text-decoration: underline;
}
.checkout-text18-close {
    color: var(--dl-color-principal);
    font-size: 12px;
    align-self: center;
    text-align: center;
    text-decoration: underline;
    display: flex;
    justify-content: center;
}
.checkout-text18:hover {
    cursor: pointer;
}
.checkout-text18-close:hover {
    cursor: pointer;
}

.checkout-container18:active {
    background-color: var(--dl-color-secundaria)!important;
    color: white!important;
    width: 27px;
    height: 27px;
}

.checkout-container21:active {
    background-color: var(--dl-color-secundaria)!important;
    color: white!important;
    width: 27px;
    height: 27px;
}

.checkout-container22 {
  flex: 0 0 auto;
  width: 350px;
  display: flex;
  margin-top: 15px;
  align-items: flex-start;
  flex-direction: column;
}

.checkout-text41 {
  font-style: normal;
  font-weight: 700;
}

.checkout-button-retornar-home {
    color: var(--dl-color-terciaria);
    height: 35px;
    font-size: 12px;
    align-self: flex-end;
    margin-top: 0px;
    text-align: center;
    transition: 0.3s;
    padding-top: 8px;
    border-width: 0px;
    padding-bottom: 8px;
    text-transform: uppercase;
    background-color: var(--dl-color-quaternaria)!important;
}
.checkout-button-retornar-home:hover {
    font-size: 14px;
    background-color: var(--dl-color-success-500);
}

.checkout-protect-group {
  transition: transform 500ms;
}

@media(max-width: 991px) {
    .pre-carrinho div img {
        width: 100%;
        max-width: 320px
    }
  .checkout-container01 {
    width: calc(100% - 20px);
    margin-top: 20px;
  }
  .checkout-container05-prod {
    width: 100%;
    display: flex;
    padding: 10px;
    flex-wrap: wrap;
    align-items: center;
    border-color: var(--dl-color-gray-900);
    border-width: 1px;
    border-radius: var(--dl-radius-radius-radius8);
    margin-bottom: 10px;
    flex-direction: column;
    justify-content: center;
    background-color: var(--dl-color-gray-white);
  }
  .checkout-text {
    font-size: 14px;
  }
  .checkout-container02 {
    width: 100%;
    flex-wrap: wrap;
  }
  .checkout-container03 {
    width: 100%;
    margin-right: 0px;
  }
  .checkout-container06 {
    justify-content: space-between;
  }
  .checkout-container08 {
    align-items: flex-start;
    width: 78%;
  }
  .checkout-container18 {
    position: relative;
    align-self: center;
    border-radius: var(--dl-radius-radius-round);
  }
  .checkout-icon05 {
    top: auto;
    fill: var(--dl-color-gray-white);
    left: 3px;
    right: auto;
    bottom: 2px;
    position: absolute;
  }
  .checkout-container21 {
    width: 30px;
    height: 30px;
    position: relative;
    align-items: center;
    border-radius: var(--dl-radius-radius-round);
    justify-content: center;
    background-color: #d2232aff;
  }
  .checkout-icon07 {
    top: auto;
    fill: var(--dl-color-gray-white);
    left: auto;
    right: 3px;
    bottom: 3px;
    position: absolute;
    align-self: center;
  }
  .checkout-container22 {
    width: 100%;
  }
  .checkout-container42 {
    justify-content: center;
  }
  .checkout-container45 {
    display: none;
  }
  .checkout-container52 {
    width: 910px;
  }
  .checkout-container55 {
    align-self: center;
  }
  
  .checkout-container-portador {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .checkout-container15 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  
  .checkout-container15-more {
    flex: 0 0 auto;
    width: 80%;
    height: auto;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .checkout-container04 {
    width: 100%;
  }
  .checkout-container05 {
    width: 100%;
  }
  .checkout-container12 {
    width: 20%;
  }
  .checkout-text09 {
    color: var(--dl-color-gray-700);
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
  }
  .checkout-container17 {
    width: 30px;
  }
  .checkout-container18 {
    width: 30px;
    height: 30px;
    position: relative;
    align-self: center;
    align-items: center;
    border-radius: var(--dl-radius-radius-round);
    justify-content: center;
    background-color: #d2232aff;
  }
  .checkout-icon05 {
    top: auto;
    fill: var(--dl-color-gray-white);
    left: 3px;
    right: auto;
    bottom: 2px;
    position: absolute;
  }
  .checkout-container20 {
    width: 30px;
  }
  .checkout-container21 {
    width: 30px;
    height: 30px;
    position: relative;
    align-self: center;
    align-items: center;
    border-radius: var(--dl-radius-radius-round);
    justify-content: center;
    background-color: #d2232aff;
  }
  .checkout-icon07 {
    top: auto;
    fill: var(--dl-color-gray-white);
    left: 3px;
    right: auto;
    bottom: 2px;
    position: absolute;
  }
  .checkout-container23 {
    width: 100%;
  }
  .checkout-container24 {
    width: 100%;
  }
  .checkout-text21 {
    font-size: 12px;
  }
  .checkout-text25 {
    font-size: 12px;
  }
  .checkout-text29 {
    font-size: 12px;
  }
  .checkout-text33 {
    font-size: 12px;
  }
  .checkout-text37 {
    font-size: 12px;
  }
  .checkout-text43 {
    color: var(--dl-color-gray-white);
  }
  .checkout-text45 {
    color: var(--dl-color-gray-white);
  }
  .checkout-text47 {
    color: var(--dl-color-gray-white);
  }
  .checkout-container-portador {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .checkout-container15 {
    flex: 0 0 auto;
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  .checkout-container15-more {
    flex: 0 0 auto;
    width: 80%;
    height: auto;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .checkout-container04 {
    width: 100%;
  }
  .checkout-container05 {
    width: 100%;
  }
  .checkout-container07 {
    width: 72px;
  }
  .checkout-container08 {
    width: 79%;
  }
  .checkout-text06 {
    font-size: 14px;
  }
  .checkout-text07 {
    font-size: 10px;
  }
  .checkout-text10 {
    align-self: flex-start;
  }
  .checkout-text11 {
    align-self: flex-start;
  }
  .checkout-text12 {
    align-self: flex-start;
  }
  .checkout-text13 {
    align-self: flex-start;
  }
  .checkout-text14 {
    align-self: flex-start;
  }
  .checkout-text15 {
    align-self: flex-start;
  }
  .checkout-container23 {
    width: 100%;
  }
  .checkout-container24 {
    width: 100%;
  }
  .checkout-container41 {
    flex-wrap: wrap;
  }
  .checkout-container42 {
    flex-wrap: wrap;
  }
  .checkout-button {
    width: 100%;
  }
  .checkout-text42 {
    color: var(--dl-color-gray-white);
  }
  .checkout-container55 {
    align-self: center;
  }

  .checkout-container15-more {
    flex: 0 0 auto;
    width: 80%;
    height: auto;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
}
</style>
